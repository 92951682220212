module.exports = {
  theme: {
    colors: {
      primary: '#ef1923',
      black: '#1a1a1a'
    },
    fonts: {
      source: [
        'Source Sans Pro',
        'Arial',
        'sans-serif'
      ]
    },
    typography: {
      body: {
        font: 'source',
        leading: 'md',
        color: 'black',
        weight: 'normal',
        size: 'md'
      },
      title: {
        leading: 'sm',
        weight: 'semibold'
      },
      paragraph: {
        leading: 'lg',
        weight: 'light'
      },
      link: {
        color: 'primary'
      }
    }
  }
}
