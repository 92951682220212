
export default {
  data() {
    return {
      links: [
        { label: 'pages.home', route: 'index' },
        { label: 'pages.products', route: 'products' },
        // { label: 'pages.about', route: 'about' },
        { label: 'pages.contact', route: 'contact' }
      ]
    }
  }
}
