
export default {
  computed: {
    logo() {
      return '/logo.svg'
    }
  },
  vessel: {
    link: {
      $attrs: 'all',
      display: 'block'
    }
  }
}
