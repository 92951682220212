
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  vessel: {
    title: {
      fontWeight: 'semibold',
      fontSize: 'md',
      marginBottom: 'md'
    },
    item: {
      fontWeight: 'light',
      marginBottom: 'sm',
      hover: {
        textColor: 'primary'
      }
    }
  },
  methods: {
    itemLabel(item) {
      if (item.translate)
        return this.$t(item.label)
      else
        return item.label
    }
  }
}
