
export default {
  data() {
    return {
      quickLinks: [
        // {
        //   path: 'about',
        //   label: 'footer.theCompany',
        //   translate: true
        // },
        {
          path: 'products',
          label: 'footer.products',
          translate: true
        },
        // {
        //   path: 'faq',
        //   label: 'footer.faq',
        //   translate: true
        // },
        {
          path: 'contact',
          label: 'footer.contact',
          translate: true
        },
        {
          path: 'cookie-policy',
          label: 'site.cookiePolicy',
          translate: true
        }
      ]
    }
  }
}
