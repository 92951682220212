export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"email":"info@wecaneurope.com","address":"43o χλμ. Ε.Ο. Αθηνών-Λαμίας, Aυλώνας Αττικής","phone":["+30 22950 42980","+30 697 229 4821"],"latitude":38.25568,"longitude":23.751405,"businessHours":[{"days":"Δευτέρα - Παρασκευή","hours":"10:00 - 17:00"},{"days":"Σάββατο","hours":"10:00 - 15:00"}]},"en":{"icons":true,"email":"info@wecaneurope.com","address":"43rd km National Road Athens - Lamia Avlon, Attica 19011","phone":"+30 697 229 4821","latitude":38.25568,"longitude":23.751405,"businessHours":[{"days":"Monday - Friday","hours":"10:00 - 17:00"},{"days":"Saturday","hours":"10:00 - 15:00"}]}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
