
export default {
  head() {
    return this.$seoMeta.head(this, {
      siteName: this.$t('siteName'),
      siteDesc: this.$t('home.metaTitle')
    })
  },
  vessel: {
    topbar: {
      paddingX: 'sm',
      paddingY: 'sm',
      backgroundColor: 'white'
    },
    footer: {
      textColor: 'black',
      fontWeight: 'light',
      overflow: 'hidden'
    }
  },
  data() {
    return {
      stuck: false
    }
  },
  computed: {
    border() {
      const border = this.$route.path != '/' || this.stuck
      return border ? true : 'transparent'
    }
  }
}
