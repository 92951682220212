export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const EshopSearchInput = () => import('../../node_modules/@evince/eshop/lib/packages/SearchInput.vue' /* webpackChunkName: "components/eshop-search-input" */).then(c => wrapFunctional(c.default || c))
export const PageEshopProducts = () => import('../../node_modules/@evince/eshop/lib/templates/Products.vue' /* webpackChunkName: "components/page-eshop-products" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const IconBox = () => import('../../components/IconBox.vue' /* webpackChunkName: "components/icon-box" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const SafetyRules = () => import('../../components/SafetyRules.vue' /* webpackChunkName: "components/safety-rules" */).then(c => wrapFunctional(c.default || c))
export const ArrowRightIcon = () => import('../../components/icons/ArrowRightIcon.vue' /* webpackChunkName: "components/arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const EmailIcon = () => import('../../components/icons/EmailIcon.vue' /* webpackChunkName: "components/email-icon" */).then(c => wrapFunctional(c.default || c))
export const FactoryIcon = () => import('../../components/icons/FactoryIcon.vue' /* webpackChunkName: "components/factory-icon" */).then(c => wrapFunctional(c.default || c))
export const FaqIcon = () => import('../../components/icons/FaqIcon.vue' /* webpackChunkName: "components/faq-icon" */).then(c => wrapFunctional(c.default || c))
export const PhoneIcon = () => import('../../components/icons/PhoneIcon.vue' /* webpackChunkName: "components/phone-icon" */).then(c => wrapFunctional(c.default || c))
export const SafetyIcon = () => import('../../components/icons/SafetyIcon.vue' /* webpackChunkName: "components/safety-icon" */).then(c => wrapFunctional(c.default || c))
export const VideoIcon = () => import('../../components/icons/VideoIcon.vue' /* webpackChunkName: "components/video-icon" */).then(c => wrapFunctional(c.default || c))
export const WarrantyIcon = () => import('../../components/icons/WarrantyIcon.vue' /* webpackChunkName: "components/warranty-icon" */).then(c => wrapFunctional(c.default || c))
export const Copyright = () => import('../../components/layout/Copyright.vue' /* webpackChunkName: "components/copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/layout/FooterColumn.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/layout/FooterColumns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const HomeHeader = () => import('../../components/layout/HomeHeader.vue' /* webpackChunkName: "components/home-header" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/layout/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/products/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardLoading = () => import('../../components/products/ProductCardLoading.vue' /* webpackChunkName: "components/product-card-loading" */).then(c => wrapFunctional(c.default || c))
export const ProductCardSmall = () => import('../../components/products/ProductCardSmall.vue' /* webpackChunkName: "components/product-card-small" */).then(c => wrapFunctional(c.default || c))
export const ProductDesktop = () => import('../../components/products/ProductDesktop.vue' /* webpackChunkName: "components/product-desktop" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/products/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/products/ProductGallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductMobile = () => import('../../components/products/ProductMobile.vue' /* webpackChunkName: "components/product-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductProperties = () => import('../../components/products/ProductProperties.vue' /* webpackChunkName: "components/product-properties" */).then(c => wrapFunctional(c.default || c))
export const ProductVariants = () => import('../../components/products/ProductVariants.vue' /* webpackChunkName: "components/product-variants" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantsMobile = () => import('../../components/products/ProductVariantsMobile.vue' /* webpackChunkName: "components/product-variants-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductsSearch = () => import('../../components/products/ProductsSearch.vue' /* webpackChunkName: "components/products-search" */).then(c => wrapFunctional(c.default || c))
export const PropertiesPreview = () => import('../../components/products/PropertiesPreview.vue' /* webpackChunkName: "components/properties-preview" */).then(c => wrapFunctional(c.default || c))
export const RelatedProducts = () => import('../../components/products/RelatedProducts.vue' /* webpackChunkName: "components/related-products" */).then(c => wrapFunctional(c.default || c))
export const BoxesSection = () => import('../../components/sections/BoxesSection.vue' /* webpackChunkName: "components/boxes-section" */).then(c => wrapFunctional(c.default || c))
export const FaqList = () => import('../../components/sections/FaqList.vue' /* webpackChunkName: "components/faq-list" */).then(c => wrapFunctional(c.default || c))
export const ProductsSection = () => import('../../components/sections/ProductsSection.vue' /* webpackChunkName: "components/products-section" */).then(c => wrapFunctional(c.default || c))
export const RotavatorSection = () => import('../../components/sections/RotavatorSection.vue' /* webpackChunkName: "components/rotavator-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
